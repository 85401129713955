<template>
  <div>
    <!--最上段メニュー-->
    <v-app-bar color="light-green lighten-4" hide-on-scroll>
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="ma-0"
          contain
          src="../assets/favicon.jpeg"
          transition="scale-transition"
          align="left"
          width="44"
        />
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title>🎍キャラDAO 年賀状SBT ミントサイト🎍</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <!--エラー表示バー-->
    <v-alert v-if="hasError" type="error">
      {{ errorMessage }}
    </v-alert>

    <!--ダイアログ
      dialog == 1 欠番
      dialog == 2 欠番
      dialog == 3 ミント成功メッセージ
      dialog == 4 ネットワーク切替の予告
      dialog == 5 欠番
      dialog == 6 欠番
      dialog == 7 ミント中の表示
      dialog == 8 トランザクション詰まった時の処置
-->

    <v-dialog v-model="dialog" v-if="dialog == 3" max-width="400px">
      <v-card max-width="100%" height="100%" class="d-flex flex-column">
        <v-img alt="Logo" class="ma-3" contain src="../assets/finished.png" />
        <v-card-text class="black--text"> ミント成功です！ </v-card-text>
        <v-card-actions>
          <v-btn
            class="mx-5"
            color="blue lighten-3"
            elevation="5"
            large
            @click="dialog = 0"
          >
            閉じる
          </v-btn>
          <v-spacer></v-spacer>
          <a v-bind:href="openseaUrl" target="_blank">
            <v-img
              max-width="30"
              contain
              src="../assets/OSLogo.svg"
              class="ma-5"
            ></v-img>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" v-if="dialog == 4" max-width="400px">
      <v-card max-width="100%" height="100%" class="d-flex flex-column">
        <v-card-text class="black--text mt-3">
          <v-icon class="mt-5 mr-auto" color="red lighten-3"
            >mdi-alert-circle</v-icon
          >
<!--          <br />{{ config.contract_network }} ネットワークに接続していません -->
          <br />ポリゴンメイン ネットワークに接続していません
          <br />メタマスクの切替画面に移ります
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-5 mb-3"
            color="blue lighten-3"
            elevation="5"
            large
            @click="
              dialog = 0;
              switchNetwork();
            "
          >
            👍OK!
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" v-if="dialog == 7" max-width="350px">
      <v-card max-width="100%" class="d-flex flex-column">
        <v-img contain src="../assets/shikibu2.gif" class="ma-5"></v-img>
        <v-card-text class="black--text">
          メタマスクで承認して、しばらくお待ちください
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" v-if="dialog == 8" max-width="400px">
      <v-card max-width="100%" height="100%" class="d-flex flex-column">
        <v-card-text class="mt-3">
          <v-icon class="mt-5 mr-auto" color="red lighten-3"
            >mdi-alert-circle</v-icon
          >
          <div class="black--text">トランザクションが詰まった様です・・・</div>
          <div>
            Metamaskで詰まっているトランザクションをキャンセルして、もう一度トライしてみて下さい
          </div>
          <br />
          <div class="black--text">Metamaskで"スピードアップ"しましたか？</div>
          <div>
            実はミントできているかも知れません
            <br /><a v-bind:href="openseaUrl" target="_blank">OpenSea</a>
            で確認してみて下さい
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-5 mb-3"
            color="blue lighten-3"
            elevation="5"
            large
            @click="gotoStart()"
          >
            最初の画面に戻る
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--ここから本体-->
    <v-container fluid class="main-part">
      <v-row justify="center">
        <v-col sm="12" md="7" lg="5">
          <v-card elevation="5">
            <v-img
              max-height="350"
              contain
              src="../assets/main_long.png"
            ></v-img>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-card>
                  <v-card-title class="pl-10 py-3 pb-0 black--text">
                   2022年は大変お世話になりました😌
                  </v-card-title>
                  <v-card-subtitle
                    v-if="walletAddress == ''"
                    class="pt-0 pb-2 black--text"
                  >
                    ...
                  </v-card-subtitle>
                  <v-card-title
                    v-if="walletAddress !== ''"
                    class="pt-5 pb-5 pl-10 py-3 green--text"
                  >
                   2023年もよろしくお願いいたします❗<br />一緒にキャラDAOを盛り上げていきましょう🚀
                  </v-card-title>
                </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card
                    v-if="walletAddress == ''"
                    class="d-flex flex-column"
                    color="yellow lighten-3"
                    elevation="5"
                  >
                    <v-card-text>
                      <div class="black--text">
                       ウォレットを接続してください
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="walletAddress ==''"
                        @click="connectWallet"
                        large
                        class="mx-5 mb-5"
                        color="red lighten-3"
                        elevation="5"
                      >
                        ウォレットを接続
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                  <v-card
                    v-if="walletAddress != '' && mintable == true"
                    class="d-flex flex-column"
                    color="yellow lighten-3"
                    elevation="5"
                  >
                  <v-img
                    max-height="350"
                    contain
                    src="../assets/main_long2.png"
                  ></v-img>
                    <v-row class="mx-0 my-1">
                      <v-col cols="12" class="d-flex align-center">
                        <v-card-text class="ma-0 pa-0 black--text">
                          <div class="black--text">
                            「ミント」ボタンを押してください。（※ガス代のみかかります）
                          </div>
                          <div class="red--text">
                            １人１枚ミントできます。あなたは {{ balanceOf }} 枚を保有済みです。
                          </div>
                          <v-card-actions class="mt-3">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="
                                mint(
                                  mintAmount,
                                  1
                                )
                              "
                              v-bind:disabled="
                                this.balanceOf == 1
                              "
                              color="red lighten-3"
                              elevation="5"
                            >
                              ミント
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card v-if="walletAddress != ''" class="d-flex flex-column">
                    <v-card-subtitle class="pl-3 py-2 black--text">
                      ミント数の合計 ( {{ totalSupply }} / ∞ )
                    <v-card-actions class="pa-0 black--text text-center">
                      <v-btn
                        fab
                        x-small
                        color="blue darken-1"
                        @click="refreshMintedStatus"
                      >
                        <v-icon dark> mdi-reload </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="5">
                  <v-card v-if="walletAddress != '' && mintable == true" class="d-flex flex-column">
                    <v-card-subtitle class="pl-3 py-2 black--text">
                      🐰おみくじ🐰
                    <v-card-actions class="pa-0 red--text text-center">
                    <button id="btn" v-on:click="omikuji" red--text>おみくじを引く</button>
                    <v-spacer></v-spacer>
                    <div>{{result}}</div>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card v-if="walletAddress != ''" class="d-flex flex-column">
                    <div
                      class="
                        pl-3
                        py-2
                        black--text black--text
                        text-decoration-underline
                      "
                    >
                      あなたのウォレットアドレス「{{
                        walletAddress.substring(0, 4)
                      }}...{{ walletAddress.substring(38, 42) }}」
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card v-if="walletAddress != '' && mintable == false" class="d-flex flex-column">
                    <div
                      class="
                        pl-3
                        py-2
                        black--text red--text
                        text-decoration-underline
                      "
                    >
                      🌱ミント期間は 2023年1月1日00時00分から1月3日23時59分（日本時間）となります🙏
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<!--本体の背景設定-->
<style>
.main-part {
  background: url("../assets/bg.png");
  width: 100%;
  height: 100vh;
}
</style>

<script>
// import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers, providers } from "ethers";
export default {
  name: "NewYear2023MintingDapp",
  data: () => ({
    //設定・URL類
    config: {},
    openseaUrl: "",
    //ウォレット接続関係
    contract: null,
    provider: null,
    isMetamask: true, //Chrome拡張機能のMetamaskがあるかどうか
    walletAddress: "",
    currenttime: 0,
    web3Provider: null, //ethers.js
    signer: null, //ethers.js
    INFURA_ID: "",
    //画面切り替え用
    dialog: 0,
    mintAmount: 1, //ユーザーが画面で設定してミントする数
    tokenid: 0, //ミントするtokenid
    balanceOf: 0, //ユーザーのミント済みの数
    mintable: false, //ユーザーが画面で設定してミントする数
    result:"", //ボタンを押すとここにおみくじの結果が出るよ
    omikujiResult:["笑大吉","大吉","中吉","小吉","凶","大凶","笑大凶"],
    //セール状況・ミント数量関係 checkContract関数で取得
    totalSupply: 0, //ミント済み総数
    //セール状況・ミント数量関係 設定ファイルから取得 ※本来不要な変数だが、分かりやすさを重視
    //データベースから取得するデータの格納先
    //保有数一覧表の項目
    //エラー処理
    hasError: false,
    errorMessage: "",
  }),
  mounted: async function () {
    try {
      //設定ファイルの読み込み
      this.hasError = false;
      this.config = require("./config/config.json");
    } catch (error) {
      console.log("error:", error.code);
      console.log("error:", error);
      this.hasError = true;
      this.errorMessage = "エラー(" + error.code + ")@mounted";
    }
  },
  methods: {
    //おみくじ
    omikuji: function () {
    const num = Math.floor(Math.random()*this.omikujiResult.length);
    this.result = this.omikujiResult[num];
    },
    // ウォレットの接続
    connectWallet: async function () {
      this.hasError = false;
      this.errorMessage = "";
      try {
        //メタマスクがないorBraveウォレットがある場合=>WalletConnectへ
        if (!window.ethereum || window.ethereum.isBraveWallet) {
          this.isMetamask = false;
          this.provider = new WalletConnectProvider({
            infuraId: this.config.INFURA_ID,
            qrcodeModalOptions: {
              desktopLinks: [],
              mobileLinks: ["metamask"],
            },
          });
          await this.provider.enable();
          this.web3Provider = new providers.Web3Provider(this.provider);
          //メタマスクがあるブラウザの場合
        } else {
          this.isMetamask = true;
          this.web3Provider = new ethers.providers.Web3Provider(
            window.ethereum
          );
          await this.web3Provider.send("eth_requestAccounts", []);
        }
        //ウォレットアドレスの取得
        this.signer = await this.web3Provider.getSigner();
        this.walletAddress = await this.signer.getAddress();
        //ポリゴンメインネット接続確認して違う場合は切替えて以降の処理を中止
        const ans = await this.checkNetwork(this.isMetamask);
        if (!ans) {
          this.dialog = 4;
          return;
        }
        //OpenSeaリンクの作成
        this.openseaUrl = this.config.opensea_url + this.walletAddress;
        //接続されたウォレットアドレスでcontractに接続
        this.contract = new ethers.Contract(
          this.config.contract_address,
          this.config.contract_abi,
          this.signer
        );
        //コントラクトからセール状況を取得
        await this.checkContract();
      } catch (error) {
        console.log("error:", error.code);
        console.log("error:", error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@connectWallet";
      }
    },
    // ミント
    mint: async function (_amount, _mode) {
      // 進行中のマークをセット
      this.dialog = 7;
      // トランザクションが詰まった時の処置用タイマー（3分待ってダメならダイアログ出す）
      const timer = setTimeout(this.giveUp, 180000);
      // contact実行
      try {
        var nftTx;
        var tx;
        if (_mode == 1) {
          nftTx = await this.contract.mint(
            this.walletAddress,
            this.tokenid,
            _amount
          );
          tx = await nftTx.wait();
          this.balanceOf = await this.contract.balanceOf(this.walletAddress, this.tokenid);
        } else if (_mode !== 1) {
          //mint
//          nftTx = await this.contract.pri2Mint(_mintAmount, this.signature, {
//            from: this.walletAddress,
//            value: ethers.utils.parseEther(_payableAmount),
//          });
//          tx = await nftTx.wait();
        }
        //表示情報の更新
        // トランザクションから返事があったら・・・
        // トランザクションが詰まった時の処置用タイマー止める
        clearTimeout(timer);
        if (tx.status == 0) {
          //トランザクション失敗
          this.dialog = 0;
          this.hasError = true;
          this.errorMessage = "トランザクションエラー";
        } else if (tx.status == 1) {
          // 完了のダイアログ表示
          this.dialog = 3;
        }
      } catch (error) {
        // 進行中のマークを解除・処置用タイマー止める
        this.dialog = 0;
        clearTimeout(timer);
        // ウォレットで拒否した場合
        if (
          error.code == "ACTION_REJECTED" ||
          error.code == "4001" ||
          error == "Error: User rejected the transaction"
        ) {
          return;
        }
        console.log(error.code);
        console.log(error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@mint";
      }
    },
    // トランザクションに詰まったかMetamaskでスピードアップした場合の退避処理
    giveUp: function () {
      this.dialog = 8;
    },
    // 接続ネットワークの切り替え
    switchNetwork: async function () {
      this.hasError = false;
      this.errorMessage = "";
      try {
        if (this.isMetamask) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: this.config.targetNetworkId }],
          });
        } else {
          const RequestArguments = {
            method: "wallet_switchEthereumChain",
            params: [{ chainId: this.config.targetNetworkId }],
          };
          // Send JSON RPC requests
          await this.provider.request(RequestArguments);
          // Close provider session
          //await provider.disconnect()
        }
        // refresh
        window.location.reload();
      } catch (error) {
        console.log("error:", error.code);
        console.log("error:", error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@switchNetwork";
      }
    },
    // 接続ネットワークの確認
    checkNetwork: async function (isMetamask) {
      this.hasError = false;
      this.errorMessage = "";
      try {
        if (isMetamask) {
          const currentChainId = await window.ethereum.request({
            method: "eth_chainId",
          });
          // return true if network id is the same
          if (currentChainId == this.config.targetNetworkId) return true;
          // return false is network id is different
          return false;
        } else {
          const currentChainId = await this.signer.getChainId();
          //          console.log("chainId=" + currentChainId);
          // return true if network id is the same
          if (currentChainId == this.config.targetNetworkId) return true;
          // return false is network id is different
          return false;
        }
      } catch (error) {
        console.log("error:", error.code);
        console.log("error:", error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@checkNetwork";
      }
    },
    // ページの強制リロード
    gotoStart: async function () {
      window.location.reload();
    },
    // コントラクトの情報取得
    checkContract: async function () {
      this.hasError = false;
      this.errorMessage = "";
      try {
        //セール状況の取得
        this.totalSupply = await this.contract.totalSupply(this.tokenid);
        //アドレスのSBT保有状況の取得
        this.balanceOf = await this.contract.balanceOf(this.walletAddress, this.tokenid);
        //現在時刻の取得
        this.currenttime = await this.contract.getCurrentUnixTime();
        //ミント可否の取得
        this.mintable = await this.contract.mintableTimer();
      } catch (error) {
        console.log("error:", error.code);
        console.log("error:", error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@checkContract";
      }
    },
    // ミント済み数の更新（リロードの負荷を下げるためcheckContractから切り出し）
    refreshMintedStatus: async function () {
      this.hasError = false;
      this.errorMessage = "";
      try {
      this.totalSupply = await this.contract.totalSupply(this.tokenid);
      this.balanceOf = await this.contract.balanceOf(this.walletAddress, this.tokenid);
      //ミント可否の取得
      this.mintable = await this.contract.mintableTimer();
      } catch (error) {
        console.log("error:", error.code);
        console.log("error:", error);
        this.hasError = true;
        this.errorMessage = "エラー(" + error.code + ")@refreshMintedStatus";
      }
    },
  },
};
</script>
