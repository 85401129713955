<template>
  <v-app>
    <v-main>
      <NewYear2023MintingDapp/>
    </v-main>
  </v-app>
</template>

<script>
import NewYear2023MintingDapp from './components/NewYear2023MintingDapp.vue'

export default {
  name: 'App',

  components: {
    NewYear2023MintingDapp,
  },

  data: () => ({
    //
  }),
}
</script>

<style>
/* Google fontsで導入したいfonts から@importのコードを取得 */
@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@700&display=swap');
#app {
/* Google fontsで導入したいfontsからfont-familyを取ってくる */
font-family: 'Zen Maru Gothic', sans-serif;
}
</style>
